"use strict";

angular.module("informaApp")
    .service("ModalHelper", ["ConstantsSvc", function (ConstantsSvc) {
        return {
            showLoadingModal: function (selector, callback) {
                this.showLoadingModalByElement($(selector), callback);
            },

            showLoadingModalByElement: function (element, callback) {
                this.showModalByElement(element, callback, {
                    backdrop: 'static',
                    keyboard: false
                });
            },

            showModal: function (selector, callback, options) {
                this.showModalByElement($(selector), callback, options);
            },

            showModalByElement: function (element, callback, options) {
                var this_ = this;

                this.onByElement(this.events.shown, element, function onShow() {
                    if (callback){
                        callback();
                    }

                    this_.offByElement(this_.events.shown, element, onShow);
                });

                element.modal(options);
            },

            hideModal: function (selector, onHide) {
                this.hideModalByElement($(selector), onHide);
            },

            hideModalByElement: function (element, onHide) {
                this.onByElement(this.events.hidden, element, onHide);

                element.modal("hide");
            },

            on: function (event, selector, callback) {
                var element = $(selector);
                this.onByElement(event, element, callback);
            },

            onByElement: function (event, element, callback) {
                if (callback) {
                    element.on(event, callback);
                }
            },

            off: function (event, selector, callback) {
                var element = $(selector);
                this.offByElement(event, element, callback);
            },

            offByElement: function (event, element, callback) {
                if (callback) {
                    element.off(event, callback);
                }
            },

            events: {
                shown: "shown.bs.modal",
                hidden: "hidden.bs.modal"
            },

            optionsAssets: {
                blockClosing: {
                    backdrop: 'static',
                    keyboard: false
                }
            }
        }
    }]);